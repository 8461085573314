<template>
  <div class="products top">
    <!--Entertainment Hero -->
    <ViewportHeight top>
      <FullBackground class="hero" :opacity="1" :image="rangeImageUrl">
        <Grid fill wrap vertical no-grow center>
          <Column center class="pad2">
            <Grid center>
              <Column :column-width="80" :below-laptop-width="100">
              </Column>
            </Grid>
          </Column>
        </Grid>
      </FullBackground>
    </ViewportHeight>

    <!-- Entertainment Sub-Hero -->
    <Section pad wrap center class="big-info gradient-back invert">
      <h1>
        Entertainment & Events
      </h1>
      <p>
        With a fully licensed bar, dedicated stage and dance floor we constantly provide a great atmosphere for all of our live entertainment and sporting events.
        Please see below for our comprehensive list of entertainment coming up, 
        we update this list so please keep coming back to this page to see what have lined up for the future.
        <br /> <br/>
        The Club has entertainment nights on a monthly basis and full details may be obtained by visiting the Club or referring to our Facebook page by clicking the button below.
      </p>

      <div class="button-cont">
        <a href="https://www.facebook.com/people/Wolstanton-Conservative-Club/61551846285805/" target="_blank" class="button big" style="margin-bottom: unset !important;">Our Facebook page</a>
      </div>
    </Section>
    
  </div>
</template>

<script>
export default {
  name: "products",
  components: {},
  data() {
    return {
      splashImageUrl: "/images/backgrounds/abstract-geometry.svg",
      rangeImageUrl: "/images/club/stage.jpg",
      craigJasonURL: "/images/events/craig-jason-1.jpg",
      schwarzkopfLogoUrl: "/images/range/logo-schwarzkopf.png",
      ghdBoxUrl: "/images/range/ghd.jpg",
      americanCrewLogoUrl: "/images/range/logo-american-crew.svg",
      americanCrewUrl: "/images/range/products.jpg",
      colorsUrl: "/images/range/colors.jpg",
      igoraTubeUrl: "/images/range/igora.png",
      entertainmentURL: "/images/events/live-entertainment.jpg",
      lisaURL: "/images/events/lisa.jpg",
      samanthaURL: "/images/events/Samantha.jpg",
      wayneRobertsURL: "/images/events/Wayne-Roberts.jpg",
      kellyWilmoreURL: "/images/events/Kelly-Wilmore.jpg",
      lesCarltonURL: "/images/events/Les-Carlton.jpg",
      helenYardleyURL: "/images/events/Helen-Yardley.jpg",
      rickJamesURL: "/images/events/Rick-James.jpg",
            
    };
  }
};
</script>

<style lang="scss" scoped>
.ghd {
  @include media($breakpoint-below-laptop) {
    text-align: center;

    p {
      margin-bottom: 3em;
    }
  }
}

.igora {
  @include media($breakpoint-below-laptop) {
    text-align: center;
  }
}

.ghd-box {
  margin-top: -6em;

  @include media($breakpoint-below-laptop) {
    margin-top: 0em;
  }
  img {
    width: 100%;
  }
}

.american-crew {
  img {
    width: 50%;
  }
}

.colors {
  background: black !important;

  color: var(--color-dark-background-text);
}

.schwarzkopt {
  @include media($breakpoint-below-laptop) {
    padding-top: 3em;
  }

  img {
    width: 70%;
  }
  .blush-wash {
    @include media($breakpoint-below-laptop) {
      height: 50%;
    }

    overflow: hidden;
    height: 100%;

    > div {
      height: 100%;

      @include media($breakpoint-below-laptop) {
        height: 150%;
      }
      > div {
        background-image: url("/images/range/blush-wash.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;

        // Float right side
        @include media($breakpoint-above-tablet) {
          position: absolute;
          background-position: left 10em;
          left: 55vw;
          right: 0;
        }

        @include media($breakpoint-laptop) {
          left: 50vw;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 25%;
          bottom: 0;
          background: linear-gradient(to bottom, transparent, #fff);
        }
      }
    }
  }
}

.range {
  width: 100%;

  > div {
    height: 100%;

    background-position: top center;
    background-size: cover;
    margin: 0 auto;
    background-repeat: no-repeat;
  }
}

.hero {
  border-bottom: 2px solid;
  border-color: var(--color-primary);

  h1 {
    margin-bottom: 0.8em;
  }
}
</style>
